// i18next-extract-mark-ns-start products-engdyn

import { ENGDYN_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import List from '@components/List';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductEngdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Essential 3D analysis for engine developers</h2>
					</Trans>

					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="ENGDYN"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							ENGDYN is a dedicated 3D engine analysis tool essential
							to the design and development of the crank train, engine
							structure and associated components including bearings,
							connecting rods and engine mounts. By offering a range
							of complexity for components, oil film models and
							solvers, ENGDYN can be used throughout the entire
							development programme, from concept through to
							production. A key benefit of ENGDYN is that users can
							quickly and efficiently design and optimise engines.
						</p>
						<h2>How it works</h2>
						<p>
							ENGDYN's application range expands by linking with other
							packages such as VALDYN, and MATLAB Simulink® to
							perform fully coupled co-simulations with data passed
							between the codes at each time step. The links with
							VALDYN are fully integrated and allow systems such as
							the front-end accessory drive/rear-end accessory drive
							(FEAD/READ) or camshaft assemblies to be fully
							integrated with the powertrain analysis.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{ENGDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<PageSection title={t("_KeyFeatures")} layout="wide">
				<Trans i18nKey="features">
					<List type="grid">
						<li>
							Hierarchy of models and solution methods within a single
							easy-to-use graphical interface
						</li>
						<li>Kinematic and dynamic solvers</li>
						<li>
							Mobility-based bearing model for rapid kinematic and
							dynamic solutions
						</li>
						<li>
							Advanced mass-conserving elastohydrodynamic (EHD)
							lubrication model for detailed bearing analysis
						</li>
						<li>Hydrodynamic and boundary lubrication models</li>
						<li>
							Link with VALDYN for valvetrain, timing drive and
							auxiliary drive loading
						</li>
						<li>
							Link to FEARCE for automated loading of finite element
							(FE) engine models
						</li>
						<li>Integrated FEARCE solver for FE matrix reduction</li>
						<li>
							3D graphical interface for rapid model generation and
							results presentation
						</li>
						<li>
							Vibro-acoustic analysis using integrated acoustic solver
						</li>
					</List>
				</Trans>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductEngdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-engdyn", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/engdyn-hero.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/rs_engdyn.png" }) {
		...imageStandard
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "ENGDYN" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
